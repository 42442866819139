// ==========================================================================
// Colors
// ==========================================================================
$main-black: #000;
$main-white: #fff;
$soft-blue: #81b6bd;
$bright-blue: #34bbcc;
$gray-ghost: #828282;
$gray: #e5eaee;
$gray2: #9c9c9c;
$gray3: #d9d9d9;
$gray4: #828282;
$gray5: #dcd9da;
$red: #ff0000;
$header-gray: #e5eaeecc;

$gradient1: linear-gradient(180deg, #aecce1 0%, #dcd9da 100%);
$gradient-2: linear-gradient(180deg, #ffffff 51.74%, rgba(255, 255, 255, 0) 100%);

// ==========================================================================
// Font
// ==========================================================================
$font-poppins: 'Poppins';

$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semi-bold: 600;
$font-bold: 700;
$font-extra-bold: 800;

// ==========================================================================
// Breakpoints
// ==========================================================================
$break-2xl: 1440px;
$break-xl: 1250px;
$break-lg: 1024px;
$break-md: 800px;
$break-sm: 600px;
$dot: 0.1px;

$cubic1: cubic-bezier(0.64, 0.57, 0.67, 1.53);
