@font-face {
  font-family: $font-poppins;
  src: url('../fonts/Poppins-Light.ttf');
  font-weight: $font-light;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font-poppins;
  src: url('../fonts/Poppins-Regular.ttf');
  font-weight: $font-regular;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font-poppins;
  src: url('../fonts/Poppins-Medium.ttf');
  font-weight: $font-medium;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font-poppins;
  src: url('../fonts/Poppins-SemiBold.ttf');
  font-weight: $font-semi-bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font-poppins;
  src: url('../fonts/Poppins-Bold.ttf');
  font-weight: $font-bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font-poppins;
  src: url('../fonts/Poppins-ExtraBold.ttf');
  font-weight: $font-extra-bold;
  font-style: normal;
  font-display: swap;
}
