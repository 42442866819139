@import '../../assets/styles/referent.scss';

.tooltip-info {
  position: absolute;
  width: auto;
  opacity: 0;
  visibility: hidden;
  transition: transform 0.3s, opacity 0.2s, visibility 0.3s;
  z-index: 999;

  &:not(.mobile) {
    top: 50%;
    left: calc(100% + 24px);
    transform: translate(80px, -50%);

    &.open {
      transform: translate(0, -50%);
      opacity: 1;
      visibility: visible;
    }
  }

  &.mobile {
    bottom: 100%;
    right: -12px;
    transform: translateY(-104px);

    &.open {
      transform: translateY(-24px);
      opacity: 1;
      visibility: visible;
    }
  }
}

.tooltip-icon {
  cursor: pointer;

  @include mediaHover {
    &:hover {
      path {
        fill: $soft-blue;
        // stroke: black;
      }

      circle {
        stroke: $soft-blue;
      }
    }
  }

  &:active {
    path {
      fill: $soft-blue;
      // stroke: black;
    }

    circle {
      stroke: $soft-blue;
    }
  }
}

.form-link {
  text-decoration: underline;
  font-size: 16px;
  line-height: 24px;
  transition: all 0.3s;

  @include mediaHover {
    &:hover {
      color: $soft-blue;
    }
  }

  &:active {
    color: $soft-blue;
  }
}
