@import '../../assets/styles/referent.scss';
.error-message {
  color: $red;
  width: 600px;
  margin-top: 5px;
  padding-right: 35px;
  font-size: 12px;
  line-height: 1.5;
  & li {
    line-height: 1.5;
  }
  @include max-md {
    padding-left: 0;
    margin-left: 0;
  }

  @include max-lg {
    width: 100%;
    padding-left: 0;
    margin-left: 0;
  }
}
.input-field {
  padding: 7px 66px 7px 19px;
  font-size: 16px;
  line-height: 24px;
  width: 600px;
  background-color: $gray;
  color: $main-black;
  border: 1px solid $main-white;
  outline: none;
  transition: border-color 0.2s;
  -webkit-appearance: none;

  @include max-md {
    padding-left: 10px;
    padding-right: 32px;
  }

  @include max-lg {
    width: 100%;
  }

  &.error {
    border: 1px solid $red;
  }

  &.gray-placeholder {
    &::placeholder {
      color: $gray-ghost;
    }
  }

  &.field-disable {
    border-color: $gray-ghost !important;
    pointer-events: none !important;
    color: $gray-ghost !important;
  }

  &.text-area {
    padding-top: 11px;
    padding-bottom: 4px;
    resize: none;
    height: 96px;
    max-height: 96px;
    line-height: 18px;
  }

  &.multi-select,
  &.dropdown-select {
    display: none;
  }

  &.number {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }

  &.file {
    padding: 0;
    padding-right: 34px;
    padding-left: 27px;

    @include max-md {
    }

    &::file-selector-button {
      font-weight: bold;
      color: $main-white;
      outline: noe;
      border: none;
      padding: 7px 0;
      background-color: $soft-blue;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      cursor: pointer;
      transition: all 0.2s;
      width: 162px;
      margin-left: -27px;
      margin-right: 27px;

      // @include max-md {
      //   display: none;
      // }

      @include mediaHover {
        &:hover {
          background-color: $main-white;
          color: $soft-blue;
        }
      }
    }
  }

  &:focus,
  &:focus-within {
    border: 1px solid $bright-blue;
  }
}

.multi-select-wrapper {
  // padding: 7px 66px 7px 19px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 16px;
  line-height: 24px;
  width: 600px;
  background-color: $gray;
  color: $main-black;
  border: 1px solid $main-white;
  outline: none;
  max-height: 160px;
  overflow: auto;
  -webkit-appearance: none;
  min-height: 40px;

  // @include max-md {
  //   padding-left: 10px;
  //   padding-right: 32px;
  // }

  @include max-lg {
    width: 100%;
  }

  &:focus,
  &:focus-within {
    border: 1px solid $bright-blue;
  }

  .select-text {
    color: $gray-ghost;
    transition: all 0.2s;
  }

  .multi-select {
    &:checked ~ .select-text {
      color: $main-black;
    }

    @include mediaHover {
      &:not(:checked):hover ~ .select-text {
        color: $soft-blue;
      }
    }
  }
  &.error {
    border: 1px solid $red;
  }
  @media (min-width: 1025px) and (max-width: 1400px) {
    &.user-form {
      width: 450px;
    }
  }

  .is-item-active {
    .select-text {
      color: $main-black;

      @include mediaHover {
        color: $main-black !important;
      }
    }
  }
}

.dropdown-field {
  font-size: 16px;
  line-height: 24px;
  width: 600px;
  background-color: $gray;
  color: $main-black;
  border: 1px solid $main-white;
  transition: border-color 0.2s;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;

  @include max-lg {
    width: 100%;
  }

  &:focus,
  &:focus-within {
    border: 1px solid $bright-blue;
  }

  &.field-disable {
    border-color: $gray-ghost !important;
    pointer-events: none;
    color: $gray-ghost;

    .arrow {
      & > svg > * {
        fill: $gray-ghost;
      }
    }
  }

  @media (min-width: 1025px) and (max-width: 1400px) {
    &.user-form {
      width: 450px;
    }
  }

  .dropdown-cur-value {
    padding: 7px 50px 7px 19px;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    min-height: 38px;

    @include max-md {
      padding-left: 10px;
    }

    .arrow {
      position: absolute;
      top: 50%;
      right: 23px;
      padding: 10px;
      transform: translateY(-50%);
      transition: transform 0.2s;

      &--up {
        transform: translateY(-50%) rotate(-180deg);
      }

      @include max-lg {
        right: 7px;
      }
    }
  }

  .dropdown-wrapper {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s;
  }

  .dropdown {
    padding-bottom: 2px;
    padding-top: 6px;
    max-height: 160px;
    overflow: auto;
  }

  .dropdown-select {
    @include mediaHover {
      &:not(:checked):hover ~ .select-text {
        color: $soft-blue;
      }
    }
  }

  .select-text {
    color: $gray-ghost;
    transition: all 0.2s;
  }

  .is-selected {
    .select-text {
      color: $main-black;
    }
  }

  &.error {
    border: 1px solid $red;
  }
}

.input-number-wrapper {
  position: relative;

  @include max-lg {
    width: 100%;
  }

  .controls {
    position: absolute;
    top: 50%;
    padding: 10px;
    right: 23px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;

    @include max-lg {
      right: 7px;
    }
  }
}

.tick {
  display: none;

  &:not(:checked) {
    & ~ .tick-icon {
      & > svg > path:nth-child(2) {
        fill: transparent !important;
      }
    }
  }
}

.tick-icon {
  cursor: pointer;

  &.field-disable {
    pointer-events: none;
    cursor: auto !important;

    & > svg > * {
      fill: $gray-ghost !important;
    }
  }

  @include mediaHover {
    &:hover {
      & > svg > * {
        fill: $bright-blue;
      }
    }
  }
}

.color-select {
  width: 50px;
  height: 50px;
  border-radius: 7px;
  border: 1px solid transparent;
  position: relative;
  background-color: $main-white;
  transition: border-color 0.2s;
  -webkit-appearance: none;

  .current-color {
    position: absolute;
    inset: 1px;
    border-radius: 7px;
  }

  &:focus,
  &:focus-within,
  &:active,
  &:hover {
    border-color: $bright-blue;
  }
}
.color-picker {
  position: absolute;
  top: 100%;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;

  &.open {
    opacity: 1;
    visibility: visible;
  }
}

.number-field-btn {
  cursor: pointer;
  padding: 1px;

  @include mediaHover {
    &:hover {
      path {
        fill: $bright-blue;
      }
    }
  }
}
.number-field-btn-disable {
  cursor: default;
  padding: 1px;
}

.ar .number-field-btn + .number-field-btn {
  margin-top: 6px;
}

.drag-drop-img {
  width: 600px;
  height: 69px;
  border: 1px solid $main-white;
  border-radius: 10px;
  transition: border 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: $gray3;
  outline: none;

  &.error {
    border-color: $red;
  }

  &:focus,
  &:focus-within {
    border: 1px solid $bright-blue;
  }

  @include max-lg {
    width: 100%;
  }
}

.coordinate-field-wrapper {
  width: 600px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;

  @include max-lg {
    width: 100%;
    flex-direction: column;
    row-gap: 20px;
  }
}

.coordinate-input-wrapper {
  position: relative;
  display: inline-block;

  .coordinate-label {
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    width: 48px;
    background-color: $gray5;
    display: inline-block;
    display: flex;
    align-items: center;
    padding-left: 16px;
  }

  .input-coordinate {
    width: 100%;
    padding: 7px 36px 7px 66px;
    border: 1px solid $main-white;
    outline: none;
    transition: border-color 0.2s;
    background-color: $gray;
    font-size: 16px;
    line-height: 24px;
    -webkit-appearance: none;

    &.number {
      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type='number'] {
        -moz-appearance: textfield;
      }
    }

    &:focus,
    &:focus-within {
      border-color: $bright-blue;
    }
    &.error {
      border: 1px solid $red;
    }
  }

  .controls {
    position: absolute;
    top: 50%;
    padding: 10px;
    right: 14px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
  }
}

.img-avatar {
  .drag-drop-img-avatar {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    transition: border 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: transparent;

    &:focus,
    &:focus-within {
      background-color: transparent;
    }
    @media (hover: hover) {
      svg:not(.no-hover):not(.stroke):hover > * {
        fill: none;
      }
    }
  }
  .avatar-reset {
    position: absolute;
    top: calc(50% - 10px);
    right: 7px;
    cursor: pointer;
  }
}

.controls-disable {
  .arrow-disable {
    fill: $gray-ghost;
  }
  @media (hover: hover) {
    svg:not(.no-hover):not(.stroke):hover > * {
      fill: $gray-ghost;
    }
  }
}

.input-field-search {
  input {
    background: transparent;
    border-color: $main-black;
    border-radius: 30px;
    font-size: 16px;
    height: 40px;
    line-height: 24px;
    outline: none !important;
    padding: 2px 19px;
    &.enrolled {
      width: unset;
    }

    &::placeholder {
      color: $main-black;
      transition: all 0.2s;
    }
    &:hover {
      &::placeholder {
        color: $gray4;
      }
    }

    &:not(:placeholder-shown) {
      & ~ .search-icon {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  &:hover {
    .clear-icon {
      &::placeholder {
        color: $gray4;
      }
    }
    svg {
      path {
        stroke: $gray4;
      }
    }
  }
}
.search-icon {
  position: absolute;
  top: 50%;
  left: 90px;
  transform: translateY(-50%);
  z-index: -1;
  transition: opacity 0.2s, visibility 0.2s;
  opacity: 1;
  visibility: visible;
}
.clear-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 12px;
  margin: auto;
  width: 20px;
  height: 20px;
}

.clear-icon:hover {
  opacity: 0.8;
  cursor: pointer;
}

@media (min-width: 1025px) and (max-width: 1400px) {
  .user-input {
    width: 450px;
  }
}
