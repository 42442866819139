// h-[40px] border-[1px] border-solid border-black bg-[transparent] w-full rounded-[30px] pl-[19px] py-[2px] pr-[90px] !outline-none text-[16px] leading-[24px] placeholder:text-black hover:placeholder:text-gray4 transition-all placeholder:transition-all
@import '../../assets/styles/referent.scss';

.search-form {
  position: relative;
  width: 100%;

  &:hover {
    .search-input {
      &::placeholder {
        color: $gray4;
      }
    }
    .clear-icon {
      &::placeholder {
        color: $gray4;
      }
    }
    svg {
      path {
        stroke: $gray4;
      }
    }
  }
}

.search-input {
  width: 100%;
  height: 40px;
  padding: 2px 90px 2px 19px;
  outline: none !important;
  border: 1px solid $main-black;
  background: transparent;
  font-size: 16px;
  line-height: 24px;
  border-radius: 30px;

  &::placeholder {
    color: $main-black;
    transition: all 0.2s;
  }

  &:not(:placeholder-shown) {
    & ~ .search-icon {
      opacity: 0;
      visibility: 0;
    }
  }
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 90px;
  transform: translateY(-50%);
  z-index: -1;
  transition: opacity 0.2s, visibility 0.2s;
  opacity: 1;
  visibility: 1;
}
.clear-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 12px;
  margin: auto;
  width: 20px;
  height: 20px;
}

.clear-icon:hover {
  opacity: 0.8;
  cursor: pointer;
}
