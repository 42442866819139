.sticky {
  position: sticky;
  top: 0;
  width: 100%;
  transition: all 0.3s;
  z-index: 9999;

  &.hide {
    transform: translateY(-100%);
  }
}

.content-wrapper {
  transform-origin: right;
}
