@import '../../assets/styles/referent.scss';

.table {
  width: 100%;
  table-layout: fixed;
  overflow-x: scroll;

  th {
    text-transform: uppercase;

    &.no-upper {
      text-transform: none;
    }
  }

  td {
    font-size: 16px;
    line-height: 24px;

    p {
      display: -webkit-box;
      min-height: 24px;
      max-height: 48px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      padding-right: 12px;
      width: 100%;
    }
  }

  .data-row {
    border-bottom: 1px solid #000000;
  }

  .colors-col {
    display: flex;
    column-gap: 11px;
    align-items: center;
  }
}

.table-col-sm {
  padding-top: 16px;
  padding-bottom: 27px;
  border-top: 3px solid $main-black;

  &:last-child {
    border-bottom: 3px solid $main-black;
  }

  .col-sm {
    display: flex;
    width: 100%;
    border-bottom: 1px solid $gray2;
    padding: 13px 0;

    &.colors {
      align-items: center;

      .content {
        display: flex;
        padding-right: 30px;
        justify-content: right;
        column-gap: 33px;
      }
    }

    .title {
      width: 20%;
      hyphens: manual;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
    }

    .content {
      width: 80%;
      padding-left: 15px;
      p {
        font-weight: 400;
        font-size: 16px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        width: 100%;
        min-height: 16px;
        max-height: 40px;
        line-height: 20px;
        text-overflow: ellipsis;
        &.csv-preview {
          overflow: unset;
          max-height: unset;
        }

        // @include max-md {
        //   min-height: 18px;
        //   max-height: 72px;
        //   -webkit-line-clamp: 4;
        // }

        &.long {
          font-weight: 400;
          font-size: 16px;
          line-height: 18px;
          display: -webkit-box;
          min-height: 18px;
          max-height: 72px;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;

          // @include max-md {
          //   min-height: 18px;
          //   max-height: 72px;
          //   -webkit-line-clamp: 4;
          // }
        }
      }
    }

    .logo {
      width: 20%;

      img {
        width: 50px;
        height: 50px;
        object-fit: cover;
      }
    }

    .buttons {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 15px;

      .button:not(:first-child) {
        margin-left: 40px;
      }

      .button {
        &.btn-disable {
          & > svg > * {
            stroke: $gray-ghost !important;
          }
        }
      }

      img {
        width: 36px;
      }
    }
  }
}

.clickable-heading {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;

  @include mediaHover {
    &:hover {
      color: $soft-blue;

      & svg {
        path {
          fill: $soft-blue;
        }
      }
    }
  }

  &.isUppercase {
    text-transform: uppercase;
  }
}

.btn-disable {
  & > svg > * {
    stroke: $gray-ghost !important;
  }
}
