@import '../../assets/styles/referent.scss';

.mobile-nav {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-image: $gradient1;
  padding: 64px 39px 43px 35px;
  transform: translateY(-100%);
  transition: transform 0.4s, visibility 0.4s;
  display: flex;
  align-items: center;
  overflow: hidden;
  z-index: 10000;
  visibility: hidden;

  &.show {
    transform: translateY(0%);
    visibility: visible;
  }
}

.close-btn {
  cursor: pointer;
  padding: 10px;
  display: inline-block;
  position: absolute;
  top: 12px;
  right: 17px;

  .wrapper {
    width: 20px;
    height: 20px;
  }

  @media screen and (max-height: 800px) {
    top: 5px;
  }
}

.sticky {
  position: sticky;
  top: 0;
  width: 100%;
  transition: all 0.3s;
  z-index: 99;

  @include min-xl {
    position: relative;
  }

  &.hide {
    @include max-xl {
      transform: translateY(-100%);
    }
  }
}

.header-wrapper {
  background-color: $header-gray;
  padding: 0 15px;

  @include min-xl {
    padding: 0;
    height: 70px;
  }
}

.mobile-horizon {
  @media (max-height: 700px) {
    height: 100%;
  }
}

.sub-item-wrapper {
  overflow-y: hidden;
  transition: max-height 0.3s;
}
