@import '../../assets/styles/referent.scss';

.table-container {
  width: 100% !important;
  table-layout: fixed;
  background-color: rgb(229 234 238 / var(--tw-bg-opacity));
  &.width-auto {
    min-width: 100%;
    width: auto !important;
    @media (min-width: 768px) {
      th {
        white-space: nowrap;
      }
    }
  }

  @include max-md {
    display: none;
  }

  .row-header-table {
    border-bottom: 3px solid #000000;
    height: auto;

    th {
      padding: 0 8px !important;
      vertical-align: bottom;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
    }

    th:first-child {
      padding-left: 0 !important;
    }

    th:last-child {
      padding-right: 0 !important;
    }
  }

  .row-data-table {
    td {
      word-break: break-word;
      white-space: normal;
      padding: 8px !important;
      vertical-align: middle;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      width: auto;
    }
    td:first-child {
      padding-left: 0 !important;
    }
    td:last-child {
      padding-right: 0 !important;
    }
    border-bottom: 1px solid #000000 !important;
  }
}

@media (max-width: 1030px) {
  .table-container {
    width: 1110px !important;
  }
}
