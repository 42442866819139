@import '../../assets/styles/referent.scss';

.tooltip-box {
  padding: 10px 10px 10px 15px;
  background-color: $gray;
  border: 1.5px solid $bright-blue;
  position: relative;

  @include max-lg {
    padding-right: 6px;
  }
}

.popup-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid $bright-blue;
  background-color: $gray;
  padding: 0 13px;
  min-height: 400px;
  display: flex;
  flex-direction: column;

  .title {
    margin-top: 87px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    text-align: center;
  }

  .description {
    flex: 1;
    width: 100%;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    margin-top: 18px;
    width: 270px;
    padding-bottom: 18px;
  }

  .btn-wrapper {
    // margin-top: 55px;

    .btn {
      padding-bottom: 70px;
    }

    .btn + .btn {
      margin-top: -28px;
      padding-bottom: 35px;
    }
  }

  .close-btn {
    position: absolute;
    top: 18px;
    right: 20px;
    cursor: pointer;
  }
}

.toast-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid $bright-blue;
  background-color: $gray;
  min-width: 300px;
  max-width: 350px;
  height: 120px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.error {
    border: 2px solid $red;
  }
  .msg {
    color: $soft-blue;
    font-size: 20px;
    line-height: 28px;
    text-transform: uppercase;
    text-align: center;
  }
}
